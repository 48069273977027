import { Select, Input, InputNumber, Checkbox } from 'antd'
import React from 'react'
import '../styles/Dashboard.css'
import dropdownOptions from '../assets/json/DropdownOptions.json'
import { faxFrequency } from '../utils/constants';


const { Search } = Input
const { Option } = Select

const FilterComponent = ({
  searchField,
  setSearchField,
  selectedEntityOption,
  setSelectedEntityOption,
  selectedAppointmentTypeOption,
  setSelectedAppointmentTypeOption,
  selectedAppointmentStatusOption,
  setSelectedAppointmentStatusOption,
  clinicNames,
  channelNames,
  filteredClinic,
  filteredChannel,
  setFilteredClinic,
  otherClinicNames,
  filteredOtherClinic,
  setFilteredOtherClinic,
  setFilteredChannel,
  selectedAtheanAppointmentStatus,
  setSelectedAthenaAppointmentStatus,
  fromScore,
  setFromScore,
  toScore,
  setToScore,
  scoreFilterOnForm,
  setScoreFilterOnForm,
  isScoredOny,
  hasGeneralInfo,
  hasInsurance,
  hasCreditCard,
  hasConsent,
  hasBooking,
  setIsScoredOnly,
  setHasGeneralInfo,
  setHasInsurance,
  setHasCreditCard,
  setHasConsent,
  setHasBooking,
  isAvailableInAmd,
  setIsAvailableInAmd,
  assessmentType,
  setAssessmentType,
  ehrType,
  faxType,
  setFaxType,
  setFaxStatus,
  faxStatus,
  setEhrType,
  stateDropDown,
  insuranceDropDown,
  apptTypeDropDown,
  stateSelected,
  setStateSelected,
  insuranceSelected,
  setInsuranceSelected,
  apptTypeSelected,
  setApptTypeSelected,
  bookingStatus,
  setBookingStatus,
  timezone,
  setTimezone,
  setLanguageSelected,
  languageSelected,
  from,
  application,
  setApplication,
  applicationOptions,
  setSpecializationSelected,
  specializationSelected,
  specializationDropDown,
  setAgepointsSelected,
  agepointsSelected,
  agepointsDropDown,
  treatmentPreferences,
  setTreatmentPreferences,
  treatmentsDropDown, 
  filters
}) => {
  const handleSearch = (value) => {
    setSearchField(value)
  }

  const handleClinicFilter = (value) => {
    setFilteredClinic(value)
  }

  const handleOtherNameClinicFilter = (value) => {
    setFilteredOtherClinic(value)
  }

  const handleChannelFilter = (value) => {
    setFilteredChannel(value)
  }

  const handleKeyDown = (e) => {
    // Prevent default behavior of unselecting the last value on backspace
    console.log(e)
    if (e.keyCode === 8) {
      setStateSelected(stateSelected)
      setApptTypeSelected(apptTypeSelected)
    }
  }

  return (
    <div className="filter-container">
      {(from === 'Booking' || from === 'Dashboard') && (
        <>
          <div className="filter-column">
            <label htmlFor="patientType" className="mb-1 labelStylingFilter">
              Select patient type
            </label>
            <Select
              id="patientType"
              className="selectInputBox"
              placeholder="Select patient type"
              value={selectedEntityOption}
              onChange={(e) => setSelectedEntityOption(e)}
              options={dropdownOptions.entityType}
            />
          </div>

          <div className="filter-column">
            <label htmlFor="appointmentType" className="mb-1 labelStylingFilter">
              Select appointment type
            </label>
            <Select
              id="appointmentType"
              className="selectInputBox"
              placeholder="Select appointment type"
              value={selectedAppointmentTypeOption}
              onChange={(e) => setSelectedAppointmentTypeOption(e)}
              options={dropdownOptions.appointmentType}
            />
          </div>

          <div className="filter-column">
            <label htmlFor="appointmentStatus" className="mb-1 labelStylingFilter">
              Select appointment status
            </label>
            <Select
              id="appointmentStatus"
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Appointment status"
              value={selectedAppointmentStatusOption}
              onChange={(e) => setSelectedAppointmentStatusOption(e)}
              optionLabelProp="label"
              options={dropdownOptions.appointmentStatus}
            />
          </div>
        </>
      )}
      {from === 'Dashboard' && (
        <>
          <Select
            placeholder="Select Clinic"
            className="selectInputLastBox"
            allowClear
            onChange={handleClinicFilter}
            value={filteredClinic}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {clinicNames.map((clinicName) => (
              <Option key={clinicName} value={clinicName}>
                {clinicName}
              </Option>
            ))}
          </Select>
          <Search
            id="search"
            className="selectInputBox"
            placeholder="Search Patient"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            onSearch={handleSearch}
            allowClear
          />
        </>
      )}

      {(from === 'Booking' || from === 'Athena' || from === 'Referral' || from === 'form' || from === 'Partner Patient') && (
        <div className="filter-column">
          {from === 'Athena' && (
            <label htmlFor="search" className="mb-1 labelStylingFilter">
              Search Patient, Clinic or Clinician
            </label>
          )}
          {(from === 'Referral' || from === 'Partner Patient') && (
            <label htmlFor="search" className="mb-1 labelStylingFilter">
              Search Patient or Clinician
            </label>
          )}
          {from === 'Booking' && (
            <label htmlFor="search" className="mb-1 labelStylingFilter">
              Search Patient by name or email
            </label>
          )}
          {from === 'form' && (
            <label htmlFor="search" className="mb-1 labelStylingFilter">
              Search Patient or Clinician
            </label>
          )}

          <Search
            id="search"
            className="selectInputBox"
            placeholder="Search"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            onSearch={handleSearch}
            allowClear
          />
        </div>
      )}

      {from === 'Athena' && (
        <>
          <div className="filter-column">
            <Checkbox id="isScored" style={{ paddingRight: 10 }} checked={isScoredOny} onChange={(e) => setIsScoredOnly(e.target.checked)} />
            <label htmlFor="isScored" className="mb-1 labelStylingFilter">
              Assessment with screening
            </label>
          </div>
          <div className="filter-column">
            <Checkbox
              id="isAvailableInAmd"
              style={{ paddingRight: 10 }}
              checked={isAvailableInAmd}
              onChange={(e) => setIsAvailableInAmd(e.target.checked)}
            />
            <label htmlFor="isAvailableInAmd" className="mb-1 labelStylingFilter">
              Appointment available in amd
            </label>
          </div>
          <div className="filter-column">
            <label htmlFor="atheanAppointmentStatus" className="mb-1 labelStylingFilter">
              Select appointment status
            </label>
            <Select
              id="athenaAppointmentStatus"
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Appointment status"
              maxTagCount="responsive"
              value={selectedAtheanAppointmentStatus}
              onChange={(e) => setSelectedAthenaAppointmentStatus(e)}
              optionLabelProp="label"
              options={dropdownOptions.atheanAppointmentStatus}
            />
          </div>
        </>
      )}

      {(from === 'form' || from === 'Clinic' || from === 'Fax' || from === 'Partner Patient') && (
        <>
          <div className="filter-column">
            <label htmlFor="clinic" className="mb-1 labelStylingFilter">
              Select Clinic
            </label>
            <Select
              id="clinic"
              placeholder="Select Clinic"
              className="selectInputLastBox"
              allowClear
              onChange={handleClinicFilter}
              value={filteredClinic}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {clinicNames.map((clinicName) => (
                <Option key={clinicName} value={clinicName}>
                  {clinicName}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}

      {from === 'Intake' && (
        <>
          <div className="filter-column">
            <label htmlFor="channel" className="mb-1 labelStylingFilter">
              Select Channel
            </label>
            <Select
              id="channel"
              placeholder="Select Channel"
              className="selectInputLastBox mb-1"
              allowClear
              onChange={handleChannelFilter}
              value={filteredChannel}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {channelNames.map((channelName, index) => (
                <Option key={index} value={channelName}>
                  {channelName}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-column">
            <label htmlFor="clinic" className="mb-1 labelStylingFilter">
              Select Clinic
            </label>
            <Select
              id="clinic"
              placeholder="Select Clinic"
              className="selectInputLastBox mb-1"
              allowClear
              onChange={handleClinicFilter}
              value={filteredClinic}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {clinicNames.map((clinicName) => (
                <Option key={clinicName} value={clinicName}>
                  {clinicName}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-column">
            <Checkbox
              id="hasGeneralInfo"
              style={{ paddingRight: 10 }}
              checked={hasGeneralInfo}
              onChange={(e) => setHasGeneralInfo(e.target.checked)}
            />
            <label htmlFor="hasGeneralInfo" className="mb-1 labelStylingFilter">
              Has General Info
            </label>
          </div>
          <div className="filter-column">
            <Checkbox id="hasInsurance" style={{ paddingRight: 10 }} checked={hasInsurance} onChange={(e) => setHasInsurance(e.target.checked)} />
            <label htmlFor="hasInsurance" className="mb-1 labelStylingFilter">
              Has Insurance
            </label>
          </div>
          <div className="filter-column">
            <Checkbox id="hasCreditCard" style={{ paddingRight: 10 }} checked={hasCreditCard} onChange={(e) => setHasCreditCard(e.target.checked)} />
            <label htmlFor="hasCreditCard" className="mb-1 labelStylingFilter">
              Has Credit Card
            </label>
          </div>
          <div className="filter-column">
            <Checkbox id="hasConsent" style={{ paddingRight: 10 }} checked={hasConsent} onChange={(e) => setHasConsent(e.target.checked)} />
            <label htmlFor="hasConsent" className="mb-1 labelStylingFilter">
              Has Consent
            </label>
          </div>
          <div className="filter-column">
            <Checkbox id="hasBooking" style={{ paddingRight: 10 }} checked={hasBooking} onChange={(e) => setHasBooking(e.target.checked)} />
            <label htmlFor="hasBooking" className="mb-1 labelStylingFilter">
              Has Booking
            </label>
          </div>
        </>
      )}

      {(from === 'Clinic' || from === 'Partner Patient') && (
        <>
          <div>
            <label htmlFor="clinic" className="mb-1 labelStylingFilter">
              Select EHR{' '}
            </label>
            <Select
              placeholder="Select EHR "
              className="selectInputLastBox"
              allowClear
              onChange={(e) => setEhrType(e)}
              value={ehrType}
              options={dropdownOptions.ehrType}
            />
          </div>
        </>
      )}

      {from === 'Clinic' && (
        <>
          <div>
            <label htmlFor="clinic" className="mb-1 labelStylingFilter">
              Select Screening Type
            </label>
            <Select
              placeholder="Select Screening Type"
              className="selectInputLastBox"
              allowClear
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => setAssessmentType(e)}
              value={assessmentType}
              options={dropdownOptions.screeningType}
            />
          </div>
          <div className="filter-column">
            <label htmlFor="clinic" className="mb-1 labelStylingFilter">
              Select Other Name of Clinic
            </label>
            <Select
              placeholder="Select Clinic"
              className="selectInputLastBox"
              allowClear
              onChange={handleOtherNameClinicFilter}
              value={filteredOtherClinic}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {otherClinicNames.map((clinicName) => (
                <Option key={clinicName} value={clinicName}>
                  {clinicName}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
      {from === 'Fax' && (
        <>
          <div className="filter-column">
            <label htmlFor="patientName" className="mb-1 labelStylingFilter">
              Patient Name
            </label>
            
            <Input 
              allowClear
              placeholder='Patient name'
              id="patientName"
              name="patientName"
              value={filters?.patientName}
              onChange={setSearchField}
            />
          </div>

          <div className="filter-column">
            <label htmlFor="patientPhone" className="mb-1 labelStylingFilter">
              Patient Phone Number
            </label>
            
            <Input
              placeholder='Patient Phone'
              id="patientPhone"
              name="patientPhone"
              value={filters?.patientPhone}
              onChange={setSearchField}
              allowClear
            />
          </div>

          <div>
            <label htmlFor="fax" className="mb-1 labelStylingFilter">
              Select Type
            </label>
            <Select
              placeholder="Select Type "
              className="selectInputLastBox"
              allowClear
              onChange={(e) => setFaxType(e)}
              value={faxType}
              options={dropdownOptions.faxType}
            />
          </div>
          <div>
            <label htmlFor="fax" className="mb-1 labelStylingFilter">
              Select Status{' '}
            </label>
            <Select
              placeholder="Select Status "
              className="selectInputLastBox"
              allowClear
              onChange={(e) => setFaxStatus(e)}
              value={faxStatus}
              options={dropdownOptions.faxStatus}
            />
          </div>
        </>
      )}
      {from === 'form' && (
        <>
          <div className="filter-column">
            <label htmlFor="assessmentType" className="mb-1 labelStylingFilter">
              Select assessment type
            </label>
            <Select
              id="assessmentType"
              className="selectInputLastBox"
              placeholder="Select assessment type"
              value={scoreFilterOnForm}
              allowClear
              onChange={(e) => setScoreFilterOnForm(e)}
              options={dropdownOptions.assessmentType}
            />
          </div>
          <div className="filter-column">
            <label htmlFor="score" className="mb-1 labelStylingFilter">
              Select Score Range
            </label>
            <div>
              <label className="selectInputNumBox" htmlFor="score">
                From
              </label>
              <InputNumber
                className="selectInputNumBox"
                min={0}
                max={100}
                value={fromScore}
                onChange={(e) => {
                  setFromScore(e)
                }}
              />
            </div>
            <div>
              <label className="selectInputNumBox" htmlFor="score">
                To
              </label>
              <InputNumber
                className="selectInputNumBox"
                min={fromScore}
                max={100}
                value={toScore}
                onChange={(e) => {
                  setToScore(e)
                }}
              />
            </div>
          </div>
        </>
      )}

      {from === 'Clinician' && (
        <>
          <div className="filter-column">
            <label htmlFor="state" className="mb-1 labelStylingFilter">
              Select state
            </label>
            <Select
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Select State"
              allowClear
              onChange={(e) => setStateSelected(e)}
              value={stateSelected}
              showSearch
              maxTagCount="responsive"
              onKeyDown={handleKeyDown}
              optionFilterProp="children"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {stateDropDown.map((state) => (
                <Option key={state.id} value={state.id}>
                  {state.title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-column">
            <label htmlFor="insurance" className="mb-1 labelStylingFilter">
              Select insurance
            </label>
            <Select
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Select Insurance"
              allowClear
              onChange={(e) => setInsuranceSelected(e)}
              value={insuranceSelected}
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {insuranceDropDown.map((insurance) => (
                <Option key={insurance.id} value={insurance.id}>
                  {insurance.title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-column">
            <label htmlFor="appt-type" className="mb-1 labelStylingFilter">
              Select appointment type
            </label>
            <Select
              onKeyDown={handleKeyDown}
              className="selectInputLastBox"
              allowClear
              mode="multiple"
              placeholder="Select appointment type"
              onChange={(e) => setApptTypeSelected(e)}
              value={apptTypeSelected}
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              optionFilterProp="children"
              maxTagCount="responsive"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              options={dropdownOptions.appointmentTypesClinician}
            ></Select>
          </div>
          <div className="filter-column">
            <label htmlFor="treatments" className="mb-1 labelStylingFilter">
              Select treatment preference
            </label>
            <Select
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Select treatment preference"
              allowClear
              onChange={(e) => setTreatmentPreferences(e)}
              value={treatmentPreferences}
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {treatmentsDropDown?.map((treatment) => (
                <Option key={treatment.id} value={treatment.treatment}>
                  {treatment.treatment}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-column">
            <label htmlFor="booking-status" className="mb-1 labelStylingFilter">
              Select available booking status
            </label>
            <Select
              id="bookingStatus"
              className="selectInputLastBox"
              value={bookingStatus}
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              placeholder="Select available booking status"
              onChange={(e) => setBookingStatus(e)}
              options={dropdownOptions.bookingStatus}
            />
          </div>
          <div className="filter-column">
            <label htmlFor="timezone" className="mb-1 labelStylingFilter">
              Select timezone
            </label>
            <Select
              id="timeZone"
              className="selectInputLastBox"
              value={timezone}
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              placeholder="Select timezone"
              onChange={(e) => setTimezone(e)}
              options={dropdownOptions.timezone}
            />
          </div>
          <div className="filter-column">
            <label htmlFor="language" className="mb-1 labelStylingFilter">
              Select language
            </label>
            <Select
              mode="multiple"
              className="selectInputLastBox"
              value={languageSelected}
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              placeholder="Select language"
              onChange={(e) => setLanguageSelected(e)}
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {dropdownOptions.language?.map((language) => (
                  <Option key={language.label} value={language.value}>
                    {language?.value}
                  </Option>
                ))}
              </Select>
          </div>
          <div className="filter-column">
            <label htmlFor="specialization" className="mb-1 labelStylingFilter">
              Select specialization
            </label>
            <Select
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Select Specialization"
              allowClear
              onChange={(e) => setSpecializationSelected(e)}
              value={specializationSelected}
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {specializationDropDown?.map((specialization) => (
                <Option key={specialization.id} value={specialization.specialisation}>
                  {specialization?.specialisation}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter-column">
            <label htmlFor="agepoints" className="mb-1 labelStylingFilter">
              Select age points
            </label>
            <Select
              mode="multiple"
              className="selectInputLastBox"
              placeholder="Select Age Points"
              allowClear
              onChange={(e) => setAgepointsSelected(e)}
              value={agepointsSelected}
              showSearch
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {agepointsDropDown?.map((agepoint) => (
                <Option key={agepoint.id} value={agepoint.agepoint}>
                  {agepoint.agepoint}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
      {from === 'AWSErrorLogs' && (
        <div className="filter-column">
          <label htmlFor="application" className="mb-1 labelStylingFilter">
            Select application
          </label>
          <Select
            id="application"
            className="selectInputLastBox"
            value={application}
            allowClear
            placeholder="Select application"
            onChange={(e) => setApplication(e)}
            options={applicationOptions}
          />
        </div>
      )}

      {from == 'MonthlyFax' && (
        <div>
          <label htmlFor="fax" className="mb-1 labelStylingFilter">
            Select Type
          </label>
          <Select
            placeholder="Select Type"
            className="selectInputLastBox"
            name="type"
            allowClear
            onChange={(value) => setSearchField({target: {name: "type", value}})}
            value={filters?.type}
            options={faxFrequency}
          />
        </div>
      )}
      
      {from === 'insuranceVerifications' && (
        <div className='filter-column'>
          <label htmlFor="filters" className="mb-1 labelStylingFilter">
            Member ID
          </label>
          <Search
            id="search"
            className="selectInputBox"
            name='memberId'
            placeholder="Member ID"
            value={filters.memberId}
            onChange={(e) => setSearchField(e.target.value)}
            onSearch={handleSearch}
            allowClear
            />
        </div>
      )}
    </div>
  )
}

export default FilterComponent
