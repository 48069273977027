import { Route, Routes, useNavigate } from "react-router-dom";
import AthenaDetails from "./AthenaDetails";
import FormDetails from "./FormsDetails";
import ClinicsDetails from "./ClinicsDetails";
import AddClinic from "./AddClinic";
import CliniciansDetails from "./CliniciansDetails";
import BookingDetails from "./BookingDetails";
import EditClinic from "./EditClinic";
import ReferralDetails from "./ReferralDetails";
import ClinicianOnboarding from "./ClinicianOnboarding";
import Analytics from "./Analytics";
import Utilities from "./Utility";
import AuditLogs from "./AuditLog";
import utilsUsers from "../utils/utilAccess";
import { useEffect } from "react";
import MonthlyFaxReview from "./MonthlyFaxReview";
import AWSErrorLogs from "./AWSErrorLogs";
import PartnerPatientDetails from "./PartnerPatientDetails";
import Intake from "./intake";
import InsuranceCaseManager from './InsuranceCaseManager';
import FormTempDetails from './FormsTempDetails';
import ParticipationMatrix from './ParticipationMatrix';
import ParticipationMatrixInsuraceMapping from "./ParticipationMatrixInsuranceMapping";
import InsuranceVerifications from "./InsuranceVerifications";
import PartnerPatientSummary from "./PartnerPatientSummary";



function MainContent() {
    // Retrieve the user object from local storage
    const user = JSON.parse(localStorage.getItem("user"));

    const navigate = useNavigate();

    // Check if the user's email have access
    const isAdmin = user && utilsUsers.includes(user.email);

    useEffect(() => {
        if (!isAdmin && window.location.pathname === '/utils') {
            navigate('/')
        }
    }, [])


    return (
        <div>
            <Routes>
                <Route path="/" element={<Analytics />} />
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/clinic" element={<ClinicsDetails />} />
                <Route path="/forms" element={<FormDetails />} />
                <Route path="/partner-patient-appointments" element={<AthenaDetails />} />
                <Route path="/partner-patients" element={<PartnerPatientDetails />} />
                <Route path="/partner-patient-summary" element={<PartnerPatientSummary />} />
                <Route path="/add-clinic" element={<AddClinic />} />
                <Route path="/edit-clinic/:id" element={<EditClinic />} />
                <Route path="/referrals" element={<ReferralDetails />} />
                <Route path="/bookings" element={<BookingDetails />} />
                <Route path="/clinicians" element={<CliniciansDetails />} />
                <Route path="/onboard-clinicians/:id" element={<ClinicianOnboarding />} />
                <Route path="/faxes" element={<AuditLogs />} />
                <Route path="/intake" element={<Intake />} />
                <Route path="/MonthlyFaxReview" element={<MonthlyFaxReview />} />
                <Route path="/insurance-case-manager" element={<InsuranceCaseManager />} />
                <Route path="/reconcile-assessment" element={<FormTempDetails />} />
                <Route path="/participation-matrix" element={<ParticipationMatrix />} />
                <Route path="/insurance-mapping" element={<ParticipationMatrixInsuraceMapping />} />
                <Route path="/insurance-verifications" element={<InsuranceVerifications />} />
                {window.location.hostname !== "portal.hellolunajoy.com" && <Route path="/aws-error-logs" element={<AWSErrorLogs />} />}
                {/* Check if the user is an admin before allowing access to Utilities */}
                {isAdmin && <Route path="/utils" element={<Utilities />} />}
            </Routes>
        </div>
    );
}

export default MainContent;
