import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import Clinic from "../assets/Clinic.svg";
import Booking from "../assets/Bookings.svg";
import Submission from "../assets/FormSubmission.svg";
import Clinician from "../assets/Clinician.svg";
import Referral from "../assets/Referrals.svg";
import Intake from "../assets/Screening.svg";
import Dashboard from "../assets/Dashboard.svg";
import PartnerPatients from "../assets/PartnerPatient.svg"
import PartnerPatientAppointments from "../assets/PatientAppointment.svg"
import AuditLog from "../assets/AuditLog.svg";
import FaxReview from "../assets/fax.png"
import { SettingOutlined, LogoutOutlined, UserOutlined, ClusterOutlined } from "@ant-design/icons";
import utilsUsers from "../utils/utilAccess";

function SideMenu({ onLogout }) {
    const navigate = useNavigate();
    const location = useLocation();
    // Assuming user information is stored in localStorage
    const user = JSON.parse(localStorage.getItem("user"));

    // Check if the user's email have access
    const isAdmin = user && utilsUsers.includes(user.email);

    const handleLogout = () => {
        onLogout()
    };

    const menuItems = [
        { label: "Performance", key: "/", icon: <img src={Dashboard} alt="Home" style={{ width: "15px" }} /> },
        // { label: "Booking Summary", key: "/dashboard", icon: <img src={BookingsSummary} alt="Booking Summary Icon" style={{ width: "15px" }} /> },
        { label: "Clinics", key: "/clinic", icon: <img src={Clinic} alt="Clinic Icon" style={{ width: "15px" }} /> },
        { label: "Clinicians", key: "/clinicians", icon: <img src={Clinician} alt="Clinician Icon" style={{ width: "15px" }} /> },
        { label: "Bookings", key: "/bookings", icon: <img src={Booking} alt="Booking Icon" style={{ width: "15px" }} /> },
        { label: "Referrals", key: "/referrals", icon: <img src={Referral} alt="Referral Icon" style={{ width: "15px" }} /> },
        { label: "Screenings", key: "/forms", icon: <img src={Submission} alt="Screening Icon" style={{ width: "15px" }} /> },
        // window.location.hostname !== "portal.hellolunajoy.com" && { label: "Monhtly FaxReview", key: "/MonthlyFaxReview", icon: <img src={FaxReview} alt="Fax Review Icon" style={{ width: "15px" }} /> },
        { label: "Partner Patient Appointments", key: "/partner-patient-appointments", icon: <img src={PartnerPatientAppointments} alt="Partner Patient Appointment Icon" style={{ width: "15px" }} /> },
        { label: "Partner Patients", key: "/partner-patients", icon: <img src={PartnerPatients} alt="Partner Patient Icon" style={{ width: "15px" }} /> },
        window.location.hostname !== "portal.hellolunajoy.com" && { label: "AWS Error Logs", key: "/aws-error-logs", icon: <img src={AuditLog} alt="AWS Error Log Icon" style={{ width: "15px" }} /> },
        { label: "Faxes", key: "/faxes", icon: <img src={FaxReview} alt="Fax Log Icon" style={{ width: "15px" }} /> },
        { label: "Intake", key: "/intake", icon: <img src={Intake} alt="Intake" style={{ width: "15px" }} /> },

        // Show "Utilities" menu item only if the user's email is admin@gmail.com
        isAdmin && { label: "Utilities", key: "/utils", icon: <SettingOutlined /> },
        { label: "Insurance Case Manager", key: "/insurance-case-manager", icon: <UserOutlined /> },
        { label: "Insurance Verifications", key: "/insurance-verifications", icon: <UserOutlined /> },
        { label: "Participation Matrix", key: "/participation-matrix", icon: <ClusterOutlined /> },
        { label: "Logout", key: "/logout", icon: <LogoutOutlined /> },
    ].filter(Boolean); // Filter out undefined values

    const handleMenuItemClick = (key) => {
        if (key === "/logout") {
            handleLogout();
        } else {
            navigate(key);
        }
    };

    return (
        <>
            <Menu
                defaultSelectedKeys={['/']}
                selectedKeys={[location.pathname]}
                onClick={({ key }) => handleMenuItemClick(key)}
                style={{ background: "#495A58", minHeight: "740px" }}
            >
                {menuItems.map((item) => (
                    <Menu.Item key={item.key} icon={item.icon} style={{ color: "white" }}>
                        {item.label}
                    </Menu.Item>
                ))}
            </Menu>
        </>
    );
}

export default SideMenu;
