import React, { useState, useEffect, useRef } from "react";
import "../styles/Details.css";
import { Modal, Table, Tag, Spin } from "antd";
import { getPartnerPatientSummary } from "../services/PartnerPatientService";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import CalendarGrey from "../assets/Calendar Dark.svg";
import CalendarBlue from "../assets/Calendar Blue.svg";

const { Column } = Table;

function PartnerPatientSummary() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const buttonRef = useRef(null);
  const datePickerRef = useRef(null);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageLimit(pagination.pageSize);
    setData([]);
    data.length = 0;
    loadData(pagination.current, pagination.pageSize);
  };

  const getSixDaysAgoFromCurrentDate = () => {
    const today = new Date();
    const sixDaysAgo = new Date(today);
    sixDaysAgo.setDate(today.getDate() - 6);
    return sixDaysAgo;
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: getSixDaysAgoFromCurrentDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const startDate = dateRange[0].startDate;
  const endDate = dateRange[0].endDate;
  const momentFormatStartDate = moment(startDate).format("D/M/YYYY");
  const momentFormatEndDate = moment(endDate).format("D/M/YYYY");

  async function loadData(pageNo, pageSize) {
    try {
      setLoading(true);
      const details = await getPartnerPatientSummary(pageNo, pageSize, startDate, endDate);
      setTotalItems(details.totalItems || 0);
      if (details.data.length) {
        setData(
          details.data.map((element) => ({
              key: element?.clinicName,
              clinicName: element?.clinicName,
              patientCount: element?.patientCount
            })
          )
        );
      }
    } catch (e) {
      console.log("failed to load data from database", e);
      Modal.confirm({
        title: "Error Message",
        content: "Oops! Error in Fetching data",
        okText: "Ok",
        cancelButtonProps: { style: { display: "none" } },
        onOk: async () => {
          console.log("Error in Fetching data");
        },
      });
    } finally {
      setLoading(false); // Reset loading once data fetching is complete
    }
    return true;
  }

  useEffect(() => {
      setCurrentPage(1);
      setPageLimit(20);
      setData([]);
      data.length = 0;
      loadData(1, 20);
    }, [startDate, endDate]);

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const handleDateChange = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleToggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  return (
    <div id="dashboard-container">
      <div className="row styleFilterRow">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: 5 }}>
            <span
              style={{
                marginLeft: "18px",
                fontWeight: 500,
                marginRight: 10,
                marginTop: 13,
              }}
            >
              Total Clinics
            </span>
            <Tag>
              <strong style={{ fontSize: 16 }}>{totalItems}</strong>
            </Tag>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span className="dateStyling">{momentFormatStartDate + " to " + momentFormatEndDate}</span>
            <span
              ref={buttonRef}
              onClick={handleToggleDatePicker}
              className="marginAdjustCalendarToggleButton"
              style={{ cursor: "pointer" }}
            >
              {showDatePicker ? <img src={CalendarBlue} alt="img" /> : <img src={CalendarGrey} alt="img" />}
            </span>
            {showDatePicker && (
              <div
                className="date-picker-container"
                ref={datePickerRef}
                style={{
                  position: "absolute",
                  zIndex: "9999",
                  top: "10.5rem",
                  display: "flex",
                  justifyContent: "end",
                  right: "30px",
                }}
              >
                <DateRangePicker ranges={dateRange} onChange={handleDateChange} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <Table
          dataSource={data}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: pageLimit,
          }}
          bordered={true}
          onChange={handleTableChange}
        >
          <Column
          title="Clinic Name"
          dataIndex="clinicName"
          key="clinicName"
        />
        <Column
          title="Number of Patients"
          dataIndex="patientCount"
          key="patientCount"
        />
        </Table>
      </Spin>
    </div>
  );
}

export default PartnerPatientSummary;