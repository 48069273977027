import axios from "axios";
import { json } from "react-router-dom";
import { BACKEND_URL } from "../utils/urls";

export default class PartnerPatientService {
  async getPartnerPatient(pageNo, pageSize, referralPlatform, clinicName, searchText, startDate, endDate) {
    const url = `${BACKEND_URL}/api/partnerPatients`;
    const config = {
      headers: { "Content-Type": "application/json" },
      params: {
        pageNo: pageNo,
        limit: pageSize,
        referralPlatform: referralPlatform ? referralPlatform : null,
        clinicName: clinicName ? clinicName : null,
        searchText: searchText,
        fromDate: startDate.toDateString(),
        toDate: endDate.toDateString(),
      },
    };

    const response = await axios.get(url, config);
    if (response.status === 200 && response?.data) {
      const resData = await response.data;

      return resData;
    } else if (response.status === 404) {
      return { data: [] };
    } else {
      throw json(
        { message: "Could not fetch events." },
        {
          status: 500,
        }
      );
    }
  }
  
  async getPartnerPatientSummary(pageNo, pageSize, startDate, endDate) {
    const url = `${BACKEND_URL}/api/partnerPatientSummary`;
    const config = {
      headers: { "Content-Type": "application/json" },
      params: {
        pageNo: pageNo,
        limit: pageSize,
        fromDate: startDate.toDateString(),
        toDate: endDate.toDateString(),
      },
    };

    const response = await axios.get(url, config);
    if (response.status === 200 && response?.data) {
      const resData = await response.data;
      return resData;
    } else if (response.status === 404) {
      return { data: [] };
    } else {
      throw {
        data: { message: "Could not fetch events." },
        status: 500,
      }
    }
  }  

  async getUniqueClinicName() {
    const url = `${BACKEND_URL}/api/getUniqueClinicNames`;
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const response = await axios.get(url, config);
    if (response.status === 200 && response?.data) {
      const resData = await response.data;

      return resData;
    } else if (response.status === 404) {
      return { data: [] };
    } else {
      throw json(
        { message: "Could not fetch events." },
        {
          status: 500,
        }
      );
    }
  }
}



export const { getPartnerPatient, getPartnerPatientSummary, getUniqueClinicName } = new PartnerPatientService();
