// EditClinic.js
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { notify } from '../utils/notify'
import { Form, Input, Button, Select, Checkbox, Spin, Modal, Switch, Upload, Image } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { getClinicDetails, updateClinic } from '../services/ClinicsService'
import { validateForm } from '../utils/validateForms'
import { faxFrequency, faxBookingTypeSync, clinicPartnerTypes, faxSequencesV2 } from '../utils/constants';
import { getStates } from '../services/StateService'
import FormItem from 'antd/es/form/FormItem'
import { getAllGHLTagMaster } from '../services/GHLTagMasterService'
import { updateStateOfTagMaster } from '../utils/GHLTagMasterUpdateState'

const { Option } = Select

const typesOfScreen = [
  { value: 'Obgyn', text: 'EPDS' },
  { value: 'PHQ9-GAD7', text: 'PHQ9-GAD7' },
  { value: 'Geriatric', text: 'GDS' }
]

const ehrSystem = ['None', 'AthenaHealth', 'eCW', 'eCW - Femwell', 'Epic', 'Cerner', 'AllScripts']
const typeOfIntegration = [
  { value: 'Sync Data', text: 'Yes' },
  { value: 'None', text: 'No' }
]

const EditClinic = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [states, setStates] = useState([])
  const [sourceTechnologyType, setSourceTechnologyType] = useState([])
  const [sourceTechnology, setSourceTechnology] = useState([])
  const [sourceOrganizationType, setSourceOrganizationType] = useState([])
  const [sourceOrganizationName, setSourceOrganizationName] = useState([])
  const [specialty, setSpecialty] = useState([])
  const [clinic, setClinic] = useState(null)
  const [loading, setLoading] = useState(false)
  const [logoImage, setLogoImage] = useState('')
  const [ehrName, setEhrName] = useState('')
  const [fileList, setFileList] = useState([])

  const loadData = async () => {
    const state = await getStates()
    setStates([{ code: '--', title: 'None' }, ...state])
    const ghlTagMasters = await getAllGHLTagMaster()
    if (ghlTagMasters) {
      updateStateOfTagMaster(
        ghlTagMasters,
        setSourceTechnologyType,
        setSourceTechnology,
        setSourceOrganizationType,
        setSourceOrganizationName,
        setSpecialty
      )
    }
    setLoading(false)
  }

  const fetchData = async () => {
    try {
      const clinicData = await getClinicDetails(id)
      setClinic(clinicData)
      setFormsFieldValues(clinicData)
    } catch (error) {
      Modal.confirm({
        title: 'Error',
        content: 'Oops! Error in fetching care partner data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
      console.error('Error fetching clinic details:', error)
    }
  }

  useEffect(() => {
    setLoading(true)
    loadData()
    fetchData()
  }, [])

  const setFormsFieldValues = (data) => {
    form.setFieldValue('name', data?.name)
    form.setFieldValue('address', data?.address.split(','))
    form.setFieldValue('faxNumber', data?.faxNumber)
    data?.faxSequence && data?.faxSequence.length > 0 && form.setFieldValue('faxSequence', data?.faxSequence)
    form.setFieldValue('isFaxScheduled', data?.isFaxScheduled)
    data?.clinicans && data?.clinicans.length > 0 && form.setFieldValue('clinicans', data?.clinicans)
    data?.typesOfScreen && data?.typesOfScreen.length > 0 && form.setFieldValue('typesOfScreen', data?.typesOfScreen)
    form.setFieldValue('ehr', data?.ehr)
    form.setFieldValue('ehrId', data?.ehrId)
    form.setFieldValue('typesOfIntegration', data?.typesOfIntegration === 'Sync Data' ? true : false)
    form.setFieldValue('allowReferral', data?.allowReferral)
    form.setFieldValue('referralLink', data?.referralLink)
    form.setFieldValue('alias', data?.alias)
    form.setFieldValue('streetName', data?.streetName)
    form.setFieldValue('faxFrequency', data?.faxFrequency);
    form.setFieldValue('faxBookingTypeSync', data?.faxBookingTypeSync);
    data['Source Technology'] && form.setFieldValue('sourceTechnology', data['Source Technology'])
    data['Source Technology Type'] && form.setFieldValue('sourceTechnologyType', data['Source Technology Type'])
    data['Source Organization Type'] && form.setFieldValue('sourceOrganizationType', data['Source Organization Type'])
    data['Source Organization Name'] && form.setFieldValue('sourceOrganizationName', data['Source Organization Name'])
    data['Specialty'] && form.setFieldValue('specialty', data['Specialty'])
    data?.logo?.data && setLogoImage(btoa(String.fromCharCode(...new Uint8Array(data?.logo.data))))
    data['partnerType'] && form.setFieldValue('partnerType', data['partnerType'])
    setEhrName(data?.ehr)
  }

  const onFinish = async (data) => {
    setLoading(true)
    const errors = validateForm(data)
    if (Object.keys(errors).length === 0) {
      // Retrieve the user object from local storage
      const user = JSON.parse(localStorage.getItem('user'))

      const formData = new FormData()
      formData.append('name', data?.name)
      formData.append('address', data?.address)
      formData.append('currentUser', user.name)
      fileList?.length && data?.logo && data?.logo.fileList.length > 0 && formData.append('logo', data?.logo.fileList[0].originFileObj)
      formData.append('faxNumber', data?.faxNumber || '')
      data?.faxSequence && formData.append('faxSequence', data?.faxSequence)
      formData.append('isFaxScheduled', data?.isFaxScheduled || false)
      data?.clinicans && formData.append('clinicans', data?.clinicans)
      data?.landingPageUrl && formData.append('landingPageUrl', data?.landingPageUrl)
      data?.typesOfScreen && formData.append('typesOfScreen', data?.typesOfScreen)
      data?.ehr && formData.append('ehr', data?.ehr)
      data?.ehrId && formData.append('ehrId', data?.ehrId)
      formData.append('typesOfIntegration', data?.typesOfIntegration ? 'Sync Data' : 'None')
      formData.append('referralLink', data?.referralLink || '')
      formData.append('alias', data?.alias || '')
      formData.append('faxFrequency', data?.faxFrequency);
      formData.append('faxBookingTypeSync', data?.faxBookingTypeSync);
      data.partnerType && formData.append('partnerType', data?.partnerType);
      data.streetName && formData.append('streetName', data?.streetName)
      data.sourceTechnology && formData.append('sourceTechnology', data?.sourceTechnology)
      data.sourceTechnologyType && formData.append('sourceTechnologyType', data?.sourceTechnologyType)
      data.sourceOrganizationType && formData.append('sourceOrganizationType', data?.sourceOrganizationType)
      data.sourceOrganizationName && formData.append('sourceOrganizationName', data?.sourceOrganizationName)
      data.specialty && formData.append('specialty', data?.specialty)
      data.allowReferral ? formData.append('allowReferral', data?.allowReferral) : formData.append('allowReferral', 'false')
      try {
        await updateClinic(id, formData)
        notify(`"${data?.name}" Clinic Updated Successfully!`)
        navigate('/clinic')
      } catch (error) {
        console.log(error)
        Modal.confirm({
          title: 'Error',
          content: (
            <div>
              <p>Oops! Failed to update care partner details.</p>
              <p>{error.message}</p>
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
      setLoading(false)
    } else {
      setLoading(false)
      const errorMessages = Object.values(errors).map((error, index) => {
        if(typeof error == 'object') {
          return error.map((err, i) => <p style={{marginBottom: 0}} key={`${i}_${index}`}>* {err}</p>);
        }else{
          return (<div key={index}>* {error}</div>)
        }
      })
      Modal.confirm({
        title: 'Error',
        content: (
          <div>
            {!errors.clinicans && <p>Oops! Can not update data due to the following errors:</p>}
            {errorMessages}
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    }
  }

  const goBack = () => {
    navigate('/clinic')
  }

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 }
  }

  if (!clinic) {
    return <div>Loading...</div>
  }

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0, img.width, img.height)

        const width = canvas.width
        const height = canvas.height

        resolve({ width, height })
      }

      img.onerror = () => {
        reject('Error loading image')
      }

      img.src = URL.createObjectURL(file)
    })
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    if (file.size > 90 * 1024) {
      onError('File size should be less than 90kb')
      setFileList([])
      Modal.confirm({
        title: 'Error',
        content: (
          <div>
            <p>File size should be less than 90kb</p>
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } }
      })
    } else {
      const dimensions = await getImageDimensions(file)
      if (dimensions.width <= 750 && dimensions.height <= 500) {
        setFileList([file])
        onSuccess('ok')
      } else {
        onError('This image does not meet the required resolution.')
        setFileList([])
        Modal.confirm({
          title: 'Error',
          content: (
            <div>
              <p>This image does not meet the required resolution.</p>
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
    }
  }

  return (
    <div id="dashboard-container" style={{ width: '800px', display: 'flex', flexDirection: 'column' }}>
      <h1 style={{ marginBottom: '20px' }}>Clinic Onboarding</h1>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} {...formItemLayout} encType="multipart/form-data">
          {/* Name */}
          <Form.Item
            name="name"
            label="Clinic name"
            rules={[
              {
                required: true,
                message: 'Please enter the name.'
              }
            ]}
          >
            <Input style={{ width: 300 }} />
          </Form.Item>

          {/* Address */}
          <Form.Item
            name="address"
            label="State"
            rules={[
              {
                required: true,
                message: 'Please select the state.'
              }
            ]}
          >
            <Select
              placeholder="Select a state"
              style={{ width: 300 }}
              mode="multiple"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {states.map((state) => (
                <Option key={state.code} value={state.code}>
                  {state.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="streetName" label="Street name">
            <Input placeholder="Enter street name" style={{ width: 300 }} />
          </Form.Item>

          {/* Assessment Type */}
          <Form.Item name="typesOfScreen" label="Type of screener">
            <Select placeholder="Select a screener type" mode="multiple" style={{ width: 300 }}>
              {typesOfScreen.map((screener) => (
                <Option key={screener.value} value={screener.value}>
                  {screener.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="referralLink" label="Referral Link">
            <Input placeholder="Enter referral link" style={{ width: 300 }} />
          </Form.Item>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Form.Item label="Logo" name="logo">
              <Upload
                maxCount={1}
                fileList={fileList}
                onRemove={(value) => {
                  setFileList([])
                }}
                customRequest={handleFileUpload}
                listType="text"
                accept=".jpg,.jpeg,.png"
              >
                <Button icon={<UploadOutlined />}>Upload logo</Button>
                <div>
                  <small>*(Size of image should be less that 90kb)</small>
                  <br />
                  <small>*(Logo dimensions must not exceed 750x500 pixels.)</small>
                </div>
              </Upload>
            </Form.Item>
          </div>
          <FormItem name="selectedImage" label="Previous logo">
            {logoImage ? (
              <Image width={170} src={`data:image/jpeg;base64,${logoImage}`} alt={clinic.name} />
            ) : (
              <p style={{ marginTop: '5px' }}>No logo uploaded before</p>
            )}
          </FormItem>

          {/* Clinic Partner Type */}
          <Form.Item name="partnerType" label="Clinic Partner Type">
            <Select placeholder="Select a clinic partner type" style={{ width: 300 }}>
              {clinicPartnerTypes?.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Fax Number */}
          <Form.Item name="faxNumber" label="Fax number">
            <Input addonBefore="+1" style={{ width: 300 }} />
          </Form.Item>

          {/* Is Fax Scheduled */}
          <Form.Item name="isFaxScheduled" label="Schedule fax">
            {form.getFieldValue('isFaxScheduled') ? <Switch defaultChecked style={{ width: 20 }} /> : <Switch style={{ width: 20 }} />}
          </Form.Item>

          {/* Fax Sequence */}
          <Form.Item name="faxSequence" label="Fax sequence">
            <Select placeholder="Select a fax sequence" mode="multiple" style={{ width: 300 }}>
              {faxSequencesV2?.map((fax) => (
                <Option key={fax} value={fax}>
                  {fax}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Fax Frequency */}
          <Form.Item name="faxFrequency" label="Fax Frequency">
            <Select placeholder="Select a fax sequence" style={{ width: 300 }}>
              {faxFrequency?.map((freq) => (
                <Option key={freq.value} value={freq.value}>
                  {freq.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Intake / Followup in Fax Report */}
          <Form.Item name="faxBookingTypeSync" label="Fax Booking Type Sync">
            <Select placeholder="Select a fax booking type sync" style={{ width: 300 }}>
              {faxBookingTypeSync?.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="clinicans" label="Clinicians">
            <Select mode="tags" placeholder="Add clinicians" style={{ width: 300 }} tokenSeparators={[',']} open={false} />
          </Form.Item>

          <Form.Item name="sourceTechnologyType" label="Source technology type">
            <Select
              placeholder="Select a source technology type"
              style={{ width: 300 }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sourceTechnologyType?.map((element) => (
                <Option key={element} value={element}>
                  {element}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="sourceTechnology" label="Source technology">
            <Select
              placeholder="Select a source technology"
              style={{ width: 300 }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sourceTechnology?.map((element) => (
                <Option key={element} value={element}>
                  {element}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="sourceOrganizationType" label="Source organization type">
            <Select
              placeholder="Select a source organization type"
              style={{ width: 300 }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sourceOrganizationType?.map((element) => (
                <Option key={element} value={element}>
                  {element}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="sourceOrganizationName" label="Source organization name">
            <Select
              placeholder="Select a source organization name"
              style={{ width: 300 }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sourceOrganizationName?.map((element) => (
                <Option key={element} value={element}>
                  {element}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="specialty" label="Specialty">
            <Select
              placeholder="Select a specialty"
              style={{ width: 300 }}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {specialty?.map((element) => (
                <Option key={element} value={element}>
                  {element}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* EHRId */}
          <Form.Item name="ehr" label="EHR name">
            <Select
              placeholder="Select a EHR"
              style={{ width: 300 }}
              onChange={(value) => {
                setEhrName(value)
              }}
            >
              {ehrSystem?.map((element) => (
                <Option key={element} value={element}>
                  {element}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* EHRId */}
          {ehrName && ehrName !== 'None' ? (
            <Form.Item
              name="alias"
              label="Clinic Name in EHR"
              extra={<small style={{ color: 'black' }}>*(Provider the clinic name send by EHR)</small>}
            >
              <Input style={{ width: 300 }} />
            </Form.Item>
          ) : null}

          {ehrName === 'AthenaHealth' ? (
            <>
              <Form.Item name="ehrId" label="Practice id">
                <Input style={{ width: 300 }} />
              </Form.Item>

              <Form.Item name="typesOfIntegration" label="Enable sync">
                {form.getFieldValue('typesOfIntegration') ? <Switch defaultChecked style={{ width: 20 }} /> : <Switch style={{ width: 20 }} />}
              </Form.Item>

              <Form.Item label="Athena referral" name="allowReferral" valuePropName="checked">
                <Checkbox></Checkbox>
              </Form.Item>
            </>
          ) : null}

          {/* Back and Submit Buttons */}
          <div style={{ display: 'flex', marginLeft: 170 }}>
            <Form.Item style={{ marginRight: 16 }}>
              <Button onClick={goBack}>Back</Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  )
}

export default EditClinic
